import { useState,useEffect } from "react";
import Axios from "axios"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectTicketById,
  useUpdateHiddenTicketMutation,
} from "./ticketsApiSlice";

const Ticket = ({ ticketId }) => {
  const ticket = useSelector((state) => selectTicketById(state, ticketId));
  const navigate = useNavigate();
  // const [gray, setGray] = useState(false);

  const [updateHiddenTicket, { isLoading, isSuccess, isError, error }] =
    useUpdateHiddenTicketMutation();





  const handelEdit = () => {
    navigate(`/dashboard/tickets/${ticketId}`);
  };

  const [hiddenMultipleClaimTickets, setHiddenMultipleClaimTickets] =
    useState(false);

  const onHandleHiddenMultipleClaimTickets = () => {
    setHiddenMultipleClaimTickets(!hiddenMultipleClaimTickets);
  };

  const onMarkAsMultipleClaimClicked = async () => {
    await updateHiddenTicket({
      ticketId: ticketId,
      isHiddenTicket: true,
    });
    // setGray(!gray);
  };

  const onRestoreAsMultipleClaimClicked = async () => {
    await updateHiddenTicket({
      ticketId: ticketId,
      isHiddenTicket: false,
    });
    //  setGray(!gray);
  };

  const isHiddenTicketClasses = ticket.isHiddenTicket
    ? "text-gray-500 bg-gray-100"
    : " ";
  const isHiddenTicketButton = ticket.isHiddenTicket ? (
    <>
      <a
        href="#"
        onClick={onRestoreAsMultipleClaimClicked}
        className="text-indigo-600 hover:text-indigo-900"
      >
        Restore tickets<span className="sr-only"></span>
      </a>
    </>
  ) : (
    <>
      <a className="text-indigo-600 hover:text-indigo-900">
        <span onClick={handelEdit} className="cursor-pointer">
          View
        </span>
        {/* <span className="cursor-pointer ml-5 text-red-600 hover:text-indigo-900">
          Delete
        </span> */}
        <span className="sr-only"></span>
      </a>
      <br />
      <a
        onClick={onMarkAsMultipleClaimClicked}
        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
      >
        Mark as Multiple claim<span className="sr-only"></span>
      </a>
    </>
  );

  const content = (
    <tr className={isHiddenTicketClasses}>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {ticket.email}
      </td>
      <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {ticket.orderNumber}
      </td>

      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {ticket.sku}
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        Rp{ticket.matchPrice}
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {ticket.ticketStatus === "Rejected" ? (
          <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
            {ticket.ticketStatus.toUpperCase()}
          </span>
        ) : ticket.ticketStatus === "Refund Submitted" ? (
          <span className="inline-flex items-center rounded-md bg-cyan-100 px-2 py-1 text-xs font-medium text-sky-600 ring-1 ring-inset ring-sky-600/20">
            {ticket.ticketStatus.toUpperCase()}
          </span>
        ) : (
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {ticket.ticketStatus.toUpperCase()}
          </span>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {ticket.multipleClaimFlag === "yes" ? (
          <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
            {ticket.multipleClaimFlag.toUpperCase()}
          </span>
        ) : (
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {ticket.multipleClaimFlag.toUpperCase()}
          </span>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {ticket.createdAt.substring(0, 10)}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        {isHiddenTicketButton}
      </td>
    </tr>
  );

  return content;
};

export default Ticket;
