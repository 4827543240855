import Axios from "axios";
import { useState, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useParams,useNavigate } from "react-router-dom";
import {useSelector} from "react-redux"
import {selectTicketById} from "./ticketsApiSlice"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import EditTicketForm from "./EditTicketForm"

export default function EditTicket() {
const { ticketId } = useParams();
 const [isEditMode, setIsEditMode] = useState(false);
 const [imgURL, setImgURL] = useState([]);
 const [imgURLData, setImgURLData] = useState([]);
 const [imgs,setImgs]=useState()
 

    
  const ticket=useSelector(state=>selectTicketById(state,ticketId))

  const navigate = useNavigate();

   useEffect(() => {
     if (ticket && ticket.imgURL) {
       setImgURL(ticket.imgURL);
     }
   }, [ticket]);

  //  console.log(("imgURL", imgURL));

   useEffect(()=>{
      if(imgURL){
        //loop though imgURL array and get the res.data form api `https://price-match.azurewebsites.net/api/v1/upload/images/${imgURL[i]}`
        //set the res.data to imgURL[i]
        //setImgURL([...imgURL,res.data])

        // const fetchImg=async()=>{
        //   const res=await Axios.get(`https://price-match.azurewebsites.net/api/v1/upload/images/${imgURL[i]}`)
        //   setImgURL([...imgURL,res.data])
        // }
        // fetchImg()
        const fetchImg = async () => {
          const res = await Promise.all(
            imgURL.map(async (img) => {
              const res = await Axios.get(
                `https://id-price-promise-11648-api-prod.azurewebsites.net/api/v1/upload/images/${img}`
              );
              return res.data;
            })
          );
          setImgURLData(res);
        };
         fetchImg();

        

         return ()=>{}
      }
    
   },[imgURL])

   useEffect(() => {
     let imgsArray = [];

     imgURLData.map((element) => element.map((item) => imgsArray.push(item)));

     setImgs(imgsArray);
   }, [imgURLData]);

 
  //  console.log("imgs",imgs);



    const handleBackToTicketsList = () => navigate("/dashboard/tickets");


 const handleEditMode=()=>{
   setIsEditMode(!isEditMode);
 }
  

  const content = ticket ? (
    <>
      <div>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <button
              type="button"
              onClick={handleBackToTicketsList}
              className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Back
            </button>
          </div>
          <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Edit
            </button> */}
            <button
              type="button"
              onClick={handleEditMode}
              className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg py-6">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Ticket Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Create at {ticket.createdAt.substring(0, 10)} / Last Update at{" "}
            {ticket.updatedAt.substring(0, 10)} {ticket.lastUpdatedPerson===null?(<></>):(<span>by {ticket.lastUpdatedPerson}</span>)}
          </p>
        </div>

        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Ticket ID</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticketId}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Order Number
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.orderNumber}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Email address
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.email}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">SKU</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.sku}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Match Price</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                Rp{ticket.matchPrice}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Match Price Date
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.matchPriceDate}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Match Price URL
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.priceMatchURL}
              </dd>
            </div>

            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Status</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {ticket.ticketStatus.toUpperCase()}
                </span>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Multiple Claim
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.multipleClaimFlag === "yes" ? (
                  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                    {ticket.multipleClaimFlag.toUpperCase()}
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {ticket.multipleClaimFlag.toUpperCase()}
                  </span>
                )}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Reject Comment
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.rejectComment ? (
                  <span>{ticket.rejectComment}</span>
                ) : (
                  <span>No Comment</span>
                )}
              </dd>
            </div>
            {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Approval Comment
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ticket.approvalComment ? (
                  <span>{ticket.approvalComment}</span>
                ) : (
                  <span>No Comment</span>
                )}
              </dd>
            </div> */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Attachments
              </dt>
              <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul
                  // role="list"
                  className="divide-y divide-gray-100 rounded-md border border-gray-200"
                >
                  {imgs?(
                  <>
                    {imgs.map((img) => (
                    <li
                      className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                      key={img.imgId}
                    >
                      <div className="flex w-0 flex-1 items-center">
                        <PaperClipIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">
                            {img.imgName} - {img.imgId}
                          </span>
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href={img.imgURL}
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Open
                        </a>
                      </div>
                    </li>
                    ))}
                  </>
                  ):(<p>Loading ...</p>)}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* <div className="mt-2 flex justify-end">
        <button
          type="submit"
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </div> */}
    </>
  ) : (
    <p>Loading Data...</p>
  );
  // const [ticket, setTicket] = useState(null);

  // useEffect(() => {
  //   const fetchTicket = async () => {
  //     const { data } = await Axiso.get(
  //       `https://price-match.azurewebsites.net/api/v1/tickets/get-single-ticket/${ticketId}`
  //     );
  //     setTicket(prevData=>({...ticket,data}));
  //   };

  //   fetchTicket();
  // }, [ticketId]);




  
 

  

  return isEditMode&&ticket ? <EditTicketForm ticket={ticket}/> : content ;
}
